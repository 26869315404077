


















































import { Component, Vue, Watch } from 'vue-property-decorator'
import Player from '@/components/common/Player.vue'
import HighlightText from '@/components/HighlightText.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import Modal from '@/components/common/Modal.vue'

@Component({
  components: {
    Player,
    HighlightText,
    ContentArea,
    Title,
    ProgressLine,
    Modal
  }
})
export default class Step4 extends Vue {
  private isModal= false
  private isText = false
  private path = 0

  private videoIsEnd (): void {
    this.isText = true
  }

  private get continueButtonTextKey (): string {
    return this.isText
      ? 'quidel.common.question_answer_button.text'
      : 'quidel.step_4.continue_button.text'
  }

  private mounted (): void {
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }

    if (localStorage.getItem('returningRouteName') === 'walkthrough 5') { this.path = 1 }
  }

  private goBack (): void {
    if (this.path) this.path--
    else this.$router.push({ name: 'walkthrough 3' })
  }

  get videoUrls (): Array<string> {
    const firstVideoUrl = this.$store.state.video.videoUrls[6]
    const secondVideoUrl = this.$store.state.video.videoUrls[7]

    return [firstVideoUrl, secondVideoUrl]
  }

  // get videoUrls (): any {
  //   const startEend = process.env.VUE_APP_VIDEO_START_END.split('/')[6]
  //   const startEend2 = process.env.VUE_APP_VIDEO_START_END.split('/')[7]
  //   return ['https://res.cloudinary.com/hey-expert/video/upload/v1631162783/BellaHealth/Oman/EN_2/English_w_English_Subtitles_from_Babylon_tcxvs2.mp4#t=' + startEend,
  //     'https://res.cloudinary.com/hey-expert/video/upload/v1631162783/BellaHealth/Oman/EN_2/English_w_English_Subtitles_from_Babylon_tcxvs2.mp4#t=' + startEend2
  //   ]
  // }

  private openModal (): void {
    if (this.$store.getters['timer/running'] || this.$store.getters['timer/finished']) {
      this.$router.push({ name: 'walkthrough 5' })
    } else {
      this.isModal = true
    }
  }

  private destroyed (): void {
    if (window.innerWidth < 768) this.$root.$off('mobile-go-back')
  }

  @Watch('path')
  onPathChange ():void {
    this.isText = false
  }
}
