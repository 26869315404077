








































import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import HighlightText from '@/components/HighlightText.vue'

@Component({
  components: {
    PrimaryButton,
    ContentArea,
    ProgressLine,
    HighlightText
  }
})
export default class Step6 extends Vue {
  public resultOptions: TestResultOption[] = []

  private selectedOption: TestResultOption | null = null

  mounted (): void {
    this.resultOptions = this.$store.getters['walkthrough/testResultOptions']
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  public isAnyOptionSelected (): boolean {
    return !!this.selectedOption
  }

  private goBack (): void {
    this.$router.push({ name: 'walkthrough 5' })
  }

  get noOptionsSelected (): boolean {
    return !this.selectedOption
  }

  public setSelectedOption (selectedOption: TestResultOption, index: number): void {
    localStorage.setItem('stripResult', index.toString())
    this.$store.dispatch('walkthrough/setSelectedOption', selectedOption)
    this.selectedOption = selectedOption
  }
}
