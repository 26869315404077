


























































































import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import { TestResultOption } from '@/store/modules/walkthrough/types/types'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import QrCode from './QrCode.vue'
import Modal from '@/components/common/Modal.vue'
@Component({
  components: {
    Title,
    PrimaryButton,
    ContentArea,
    ProgressLine,
    Modal,
    QrCode
  }
})
export default class Step7 extends Vue {
  public resultOptions: TestResultOption[] = []
  private selectedOption: TestResultOption | null = null
      isCameraOpen = false
      isPhotoTaken = false
      isShotPhoto = false
      isLoading = false
      captureQR = true
      modalOpen = false
      mounted (): void {
        this.resultOptions = this.$store.getters['walkthrough/testResultOptions']
        if (window.innerWidth < 768) {
          this.$root.$on('mobile-go-back', () => {
            this.goBack()
          })
        }
      }

      private goBack (): void {
        this.$router.push({ name: 'walkthrough 6' })
        this.toggleCamera()
      }

      private goTo (): void {
        this.$router.push({ name: 'walkthrough result' })
      }

      // eslint-disable-next-line space-before-function-paren
      private openQrCode(): void {
        this.$router.push({ name: 'qrpage' })
        // this.isCameraOpen = true
        // this.captureQR = true
        // localStorage.setItem('takenPicture', '')
        // setTimeout(() => {
        //   const qrcodebtn: any = document.querySelector('.qrcode-stream-wrapper')
        //   if (qrcodebtn) {
        //     qrcodebtn.click()
        //     qrcodebtn.scrollIntoView()
        //     qrcodebtn.focus()
        //   }
        // }, 2000)
      }

      private modalClose (): void {
        this.modalOpen = !this.modalOpen
        this.isCameraOpen = true
        this.createCameraElement()
      }

      public qrFunction (code:string): void {
        this.createCameraElement()
        console.log('code: ' + code)
        this.captureQR = false
        if (localStorage.getItem('serialNumber') as string === code) {
          localStorage.setItem('cartridge_verified', 'true')
        }
        this.modalOpen = true
        // this.createCameraElement()
        // setTimeout(() => {
        //   this.isPhotoTaken = true
        //   var link:any = document.getElementById('photoTaken')
        //   link.click()
        //   // setTimeout(() => {
        //   // }, 500)
        //   // this.takePhoto()
        //   // this.isPhotoTaken = false
        //   // const context = (this.$refs.canvas as HTMLCanvasElement).getContext('2d') as CanvasRenderingContext2D
        //   // context.drawImage(this.$refs.camera as HTMLCanvasElement, 0, 0, 320, 240)
        //   // console.log('this.isPhotoTaken', context)
        //   // this.stopCameraStream()
        // }, 500)
      }

      private takeAnotherQr () {
        this.stopCameraStream()
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.captureQR = true
        this.toggleCamera()
      }

      private takeAnotherPhoto () {
        this.stopCameraStream()
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.captureQR = false
        this.toggleCamera()
      }

      private toggleCamera () {
        if (this.isCameraOpen) {
          this.isCameraOpen = false
          this.isPhotoTaken = false
          this.isShotPhoto = false
          this.stopCameraStream()
        } else {
          localStorage.setItem('takenPicture', '')
          this.isCameraOpen = true
          this.createCameraElement()
        }
      }

      private createCameraElement () {
        this.isLoading = true
        const win: any = window
        const constraints = (win.constraints = {
          audio: false,
          video: {
            facingMode:
            { ideal: 'environment' }
          }
        })
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false
            let srObj:any = this.$refs.camera
            srObj = srObj.srcObject = stream
          })
          .catch(() => {
            this.isLoading = false
            // alert("May the browser didn't support or there is some errors.")
          })
      }

      private stopCameraStream () {
        let tracks:any = this.$refs.camera
        tracks = tracks.srcObject.getTracks()
        tracks.forEach((track: { stop: () => void }) => {
          track.stop()
        })
      }

      private takePhoto () {
        // if (!this.isPhotoTaken) {
        //   this.isShotPhoto = true
        //   const FLASH_TIMEOUT = 50
        //   setTimeout(() => {
        //     this.isShotPhoto = false
        //   }, FLASH_TIMEOUT)
        // }
        this.isPhotoTaken = true
        const context = (this.$refs.canvas as HTMLCanvasElement).getContext('2d') as CanvasRenderingContext2D
        context.drawImage(this.$refs.camera as HTMLCanvasElement, 0, 0, 320, 240)
        setTimeout(() => {
          this.downloadImage()
        }, 1000)
      }

      private downloadImage () {
        const canvas = (document.getElementById('photoTaken') as HTMLCanvasElement)
          .toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
        // console.log(canvas)
        // QrScanner.scanImage(canvas).then(result => console.log('result', result)).catch(error => console.log(error || 'No QR code found.'))
        localStorage.setItem('takenPicture', canvas)
        // const download = document.getElementById('downloadPhoto')
        // eslint-disable-next-line no-unused-expressions
        // download?.setAttribute('href', canvas)
        this.stopCameraStream()
      }
}
