













import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'

@Component({
  components: {
    ContentArea,
    Title,
    ProgressLine,
    Screen_1: () => import('./Screen_1.vue'),
    Screen_2: () => import('./Screen_2.vue')
  }
})
export default class Step5 extends Vue {
  private componentName: string | null = 'Screen_1'

  private mounted (): void {
    localStorage.removeItem('returningRouteName')

    this.$root.$on('open-screen', (screenName: string) => {
      this.componentName = screenName
    })
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    switch (this.componentName) {
      case 'Screen_2':
        this.componentName = 'Screen_1'
        break
      case 'Screen_1':
        localStorage.setItem('returningRouteName', this.$route.name as string)
        this.$router.push({ name: 'walkthrough 4' })
        break
    }
  }

  private openScreen (screenName: string): void {
    this.componentName = screenName
  }

  private destroyed (): void {
    this.$root.$off('open-screen')
    sessionStorage.removeItem('open-screen')
    if (window.innerWidth < 768) this.$root.$off('mobile-go-back')
  }
}
